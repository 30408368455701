var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('v-card',{staticClass:"mb-10"},[_c('v-tabs',{attrs:{"bg-color":"primary"},model:{value:(_vm.tabulacao),callback:function ($$v) {_vm.tabulacao=$$v},expression:"tabulacao"}},[_c('v-tab',{attrs:{"value":1}},[_vm._v("Total por aluno")]),_c('v-tab',{attrs:{"value":2}},[_vm._v("Total por período")]),_c('v-tab',{attrs:{"value":3}},[_vm._v("Total por Turma")])],1)],1),(_vm.tabulacao == 0)?_c('div',[_c('h4',{staticClass:"pb-1"},[_vm._v("Total por aluno")]),_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquise por alunos, matrícula, turma","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","label":"pesquisar data","readonly":"","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.searchDateString),callback:function ($$v) {_vm.searchDateString=$$v},expression:"searchDateString"}},'v-text-field',attrs,false),on))]}}],null,false,3985047027),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[
                {
                  text: 'Todos',
                  value: 'All',
                },
                {
                  text: 'Matutino',
                  value: 'MORNING',
                },
                {
                  text: 'Vespertino',
                  value: 'EVENING',
                },
                {
                  text: 'Noturno',
                  value: 'NOCTURNAL',
                },
                {
                  text: 'Integral',
                  value: 'INTEGRAL',
                },
              ],"label":"Período","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.searchSession),callback:function ($$v) {_vm.searchSession=$$v},expression:"searchSession"}})],1)],1),_c('v-data-table',{staticClass:"no-line-row",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 30, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.session`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(item.studentClass && _vm.periodSessionType[item.studentClass.session]))+" ")]}},{key:`item.ageGroup`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(_vm.ageGroupType[item.ageGroup]))+" ")]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.tabulacao == 1)?_c('div',[_c('list-period')],1):_vm._e(),(_vm.tabulacao == 2)?_c('div',[_c('list-period-session-name')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }